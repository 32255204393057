import { render, staticRenderFns } from "./Terms.vue?vue&type=template&id=535d3192&scoped=true&"
var script = {}
import style0 from "./Terms.vue?vue&type=style&index=0&id=535d3192&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "535d3192",
  null
  
)

export default component.exports